export class Loja {
    agrupador;
    totalGeral = 0
    bandeiras = []

    get descricao(){
        return this.descricoes ? this.descricoes.toString() : null
    }
}

export class Bandeira {
    agrupador;
    descricao;
    totalGeral = 0;
    vendas = [];
    cartoes = [];
}

export class LojaMergida {
    constructor() {
        this.id = "loja-mergida-" + parseInt(Math.random()*999999999)
    }
    id;
    descricao;
    descricaoLojasMergidas = []
    valorTotalConciflex = 0.0;
    valorTotalFlex = 0.0;
    bandeiras = []

    comparar(outraLoja){
        if (this.descricao > outraLoja.descricao){
            return 1
        }else if (this.descricao < outraLoja.descricao){
            return -1
        }else{
            return 0
        }
    }
}

export class BandeiraMergida {
    constructor() {
        this.id = "bandeira-mergida-" + parseInt(Math.random()*999999999)
    }
    id;
    descricao;
    agrupador;
    descricaoBandeirasMergidas = []
    valorTotalConciflex = 0.0;
    valorTotalFlex = 0.0;
    vendasFlex = []
    vendasConciflex = []

    comparar(outraBandeira){
        if (this.agrupador > outraBandeira.agrupador){
            return 1
        }else if (this.agrupador < outraBandeira.agrupador){
            return -1
        }else{
            return 0
        }
    }
}



export const LOJA_CONCIFLEX_TO_FLEX = {
    "ITALO 008 - SLO": ["008"],
    "ITALO 014 - FOZ REP": ["114", "014"],
    "ITALO 002 - DVZ": ["002"],
    "ITALO 004 - SMO": ["004"],
    "ITALO 013 - ATUBA": ["013"],
    "ITALO 016 - VILA A": ["016", "116"],
    "ITALO 021 - PORTÃO": ["021"],
    "ITALO 020 - FOZ PORTO": ["020"],
    "ITALO 015 - FOZ CENTRO": ["015", "115"],
    "ITALO 007 - CN": ["007"],
    "ITALO 009 - CLE": ["009"],
    "ITALO 017 - MANG": ["017"],
    "ITALO 010 - AMP": ["010"],
    "ITALO 018 - AB LUZ": ["018"],
    "ITALO 001 - FB": ["001"],
    "ITALO 080 - MATRIZ": ["080"],
    "ITALO 011 - PIN": ["011"],
    "ITALO 100 - LAR": ["100"],
    "ITALO 019 - SUPER DIA": ["019"],
}

export const BANDEIRA_FLEX_TO_CONCIFLEX = {
    "Cartão Ticket Alimentação Eletrônico": ["TICKET ALIMENTACAO"],
    "Cartão Ticket Refeição": ["TICKET REFEICAO"],
    "Cartão Crédito Mastercard": ["MASTER CREDITO", "MASTER PARCELADO"],
    "PIX": [""],
    "Cartão Débito Maestro": ["MAESTRO"],
    "Cartão Débito Elo": ["ELO DEBITO A VISTA"],
    "Cartão Débito Visa Electron": ["VISA ELECTRON DEBITO A VISTA"],
    "Cartão Crédito Elo": ["ELO CREDITO", "ELO PARCELADO LOJA"],
    "Cartão Ticket Alelo": ["VISA ALELO ALIMENTACAO"],
    "Cartão Crédito Visanet": ["VISA CREDITO A VISTA", "VISA PARCELADO LOJA"],
    "Cartão Ticket V.R. Beneficios": ["VR ALIMENTACAO", "VR REFEICAO"],
    "Cartão Ticket Bonus Cred": ["BonusCred Outros", "Cartao Pre-Datado"],
    "Cartão Ticket Maxiscard": [""],
    "Cartão Ticket Coopercred": ["Coopercard Débito", "Coopercard Crédito"],
    "Cartão Ticket Abrapetite": ["Abrapetite Voucher"],
    "Cartão Ticket SodexHopass": ["SODEXO ALIMENTACAO", "SODEXO GIFT PASS", "SODEXO PREMIUM PASS"],
    "Cartão Ticket Sodexo Refeição": ["SODEXO REFEICAO"],
    "Cartão Ticket BEN - Visa Vale Alimentação": ["Ben Visa Vale Alimentação"],
    "Cartão Ticket Nutricard": ["NutriCard Refeição", "NutriCard Alimentação", "NutriCard Outros"],
    "Cartão Ticket Alelo Refeição": ["VISA ALELO REFEICAO", "Alelo Multibenefícios"],
    "Cartão Ticket LivCard": [""],
    "Cartão Crédito American Express": ["AMEX CREDITO A VISTA"," AMEX CREDITO PARCELADO"],
    "Cartão Ticket Vegas Alimentação": [""],
    "Cartão Ticket Alimentação Cabal": ["CABAL VOUCHER"],
    "Cartão Ticket Alimentare Beneficios": ["Alimentare Alimentação"],
    "Cartão de Débito Cabal": ["CABAL DEBITO"],
    "Cartão Ticket Personal Card/Triocard": ["Personal Card Débito", "Personal Card  Débito","Personal Card Crédito","Personal Card Crédito"],
    "Cartão Ticket Utilcard - Alimentação": ["Convcard Benefício"],
    "Cartão Ticket Greencard": ["Green Card Débito"],
    "Cartão Ticket Débito Verde Card": ["VERDECARD CREDITO", "VERDECARD PARCELADO"],
    "Cartão Crédito Cabal": ["CABAL CREDITO A VISTA","CABAL PARCELADO LOJA"],
    "Cartão de Crédito Ticket Biq Beneficios": ["BIQ Voucher"],
    "Cartão Viasoft Pay": [""],
    "Cartão Ticket Carteira Digital PicPay": [""],
    "Cartão Crédito Ticket Hipercard": ["HIPERCARD CREDITO A VISTA","HIPERCARD PARCELADO LOJA"],
    "Cartão de Crédito Ticket Senff": ["SENFF CREDITO"],
    "Cartão Crédito Ticket Policard": ["Policard Cupom Eletrônico"],
    "Cartão Brasil Card": ["Brasil Convênio Débito","Brasil Convênio Crédito"],
    "Cartão Ticket VS Card": [""],
    "Cartão Ticket BEN - Visa Vale Refeição": ["Ben Visa Vale Refeição"],
    "Cartão Ticket ValeCard": ["Valecard Voucher"],
    "Cartão Ticket Brasil Convenios": [""],
    "Cartão Ticket Masterfarma": [""],
    "Cartão Ticket Nutricash": [""],
    "Cartão Ticket Diamante": [""],
    "Cartão Ticket Eucard Alimentação": [""],
    "Cartão Crédito Ticket GoodCard": ["GOODCARD CREDITO"],
    "Cartão Ticket Siga": [""],
    "Cartão de Crédito Ticket Verocheque": [""],
    "Cartão de Credito Ticket Calcard": ["Calcard Crédito"],
    "Cartão de Crédito Ticket Mettacard": [""],
    "Cartão de Crédito Ticket Sorocred": ["SOROCRED CREDITO A VISTA"],
    "Cartão de Crédito Ticket Prédatado": ["Cartão Pré-Datado Outros"],
    "Cartão Ticket JCB": [""],
    "Cartão Qualidade": [""],
    "Cartão Brasil Card": [""],
    "Cartão Rappi": [""],
    "Cartão CréditoTicket GoodCard": ["GOODCARD CREDITO"],
    "Cartão de Crédito Ticket Predatado": ["Cartão Pré-Datado Outros"],
    "Cartão BK Bank": ["Cartão BK Bank"],
    "Cartão de Crédito Sindpluscred": ["Cartão de Crédito Sindpluscred"],
    "Cartao Débito MeuVale": ["Cartao Débito MeuVale"],
    "Cartão Ticket UP Brasil": ["Cartão Ticket UP Brasil"]
}

export const loadConfigCardFromStorage = (key, defaultValues) =>{
    let fromLocalStorage = window.localStorage.getItem(key)
    if (fromLocalStorage){
        return JSON.parse(fromLocalStorage)
    }
    return defaultValues
}