import React, { useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Template from './Template';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import { CONFIG_OPTION_FILES, convertSheetArrayToLojaArray, convertToArray, mergeLojas } from './helper/ComparatorUtil';
import TreeLoja from './components/TreeLojas';
import { BandeiraMergidaInfo, LojaMergidaInfo } from './components/ComponentesComparador';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { Configuracoes } from './components/configuracoes';

export default function Comparador() {

    const [executando, setExecutando] = useState(false) 
    const [files, setFiles] = useState([]) 
    const arquivosRef = useRef(null)
    const [resultado, setResultado] = useState(null) 
    const [elementoSelecionado, setElementoSelecionado] = useState(null) 
    const { enqueueSnackbar } = useSnackbar();

  const onFileChange = (event, type) => {
       const uploadedFile = event.target.files[0]
       let filesCopy = Object.assign([], files)
       filesCopy[type] = {
            type,
            file: uploadedFile,
            config: CONFIG_OPTION_FILES[type]
       }
       setFiles(filesCopy)
  }  

  const compararArquivos = () => {

    if (Object.keys( files ).length !== 2){
        enqueueSnackbar('É necessário informar os 2 arquivos', { 
            variant: 'error',
        });
        return;
    }

    setExecutando(true)
    arquivosRef.current = []

     Object.keys( files ).map(key => {
        
        const currentFile = files[key];
        console.log("Carregando arquivo da ", currentFile)
        
        const xlsFile = files[ currentFile.type ].file;
        console.log("Nome do arquivo ", xlsFile.name)

        convertToArray(xlsFile, function (xlsArray) {
                convertSheetArrayToLojaArray(xlsArray, currentFile.config)
                    .then(lojas => {
                        console.log("Lojas " + currentFile.type, lojas)

                        try{
                            onCompleteFileConverter(currentFile.type, lojas)
                        } catch(err){
                            console.error(err)
                            enqueueSnackbar(err, { 
                                variant: 'error',
                            });
                            setExecutando(false)
                        } 

                    }).catch(err => {
                        setExecutando(false)
                        console.error("convertSheetArrayToLojaArray", err)
                    })
            })

     })

  }


  const onCompleteFileConverter = (type, lojas) => {
    arquivosRef.current[type] = lojas
    console.log("Conversão do arquivo " + type, arquivosRef.current)

    const lojasKey = Object.keys(arquivosRef.current)
    if ( lojasKey.length  === 2){
        console.log("TERMINOU!")
        
        const lojasFlex = arquivosRef.current['FLEX']
        const lojasConciflex = arquivosRef.current['CONCIFLEX']
        const lojasMergidas = mergeLojas(lojasConciflex, lojasFlex)
        
        console.log("LOJAS MERGIDAS", lojasMergidas)

        //sort
        const lojasMergidasSorted = lojasMergidas.sort( function(a, b) { return  a.comparar(b) } )
        lojasMergidasSorted.map(lojaMergidaESorted => {
            lojaMergidaESorted.bandeiras = lojaMergidaESorted.bandeiras.sort( function(a, b) { return  a.comparar(b) } )
        })

        console.log("LOJAS MERGIDAS E ORDENADAS", lojasMergidasSorted)

        setExecutando(false)

        const resultadoLojasMergidas = {
            id: "resultado",
            lojasMergidas: lojasMergidasSorted
        }

        setResultado(resultadoLojasMergidas)
    }
  }

  const onClickBandeira = (bandeiraMergida, lojaMergida) =>{
      console.log("Clicou na bandeira", bandeiraMergida, lojaMergida)

      setElementoSelecionado({
        lojaMergida,
        bandeiraMergida
      })
  }

  return (
     <Template>
        <div>
        

       
        <Grid container justify="center" spacing={2}>
         
            <Grid item xs={6}>
              <Paper>
                <Box p={2}>
                    <Typography display="block">Planilha do Conciflex</Typography>
                    <input type="file" onChange={event => onFileChange(event, 'CONCIFLEX')} />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper>
                <Box p={2}>
                    <Typography display="block">Planilha do Flex</Typography>
                    <input type="file" onChange={event => onFileChange(event, 'FLEX')} />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12}>
                
                <Button variant="contained" 
                    disabled={executando}
                    color="primary" onClick={compararArquivos}>{executando ? "Comparando. Aguarde..." : "Comparar planilhas"}</Button>
            
            </Grid>

            
         
        </Grid>

        <Box pt={5}>

        <Grid container justify="center" spacing={2}>
         
            <Grid item xs={6}>
                <Box style={{
                    minHeight: "95vh",
                    overflowY: 'auto'
                }}>
                
                {executando && <Skeleton variant="rect"  height={100} />}
            
                { resultado ? 
                        <TreeLoja dados={resultado} onClickBandeira={onClickBandeira}/> 
                : null }

                </Box>
            </Grid>
            
            <Grid item xs={6}>
                
                {executando && <Skeleton variant="rect"  height={100} />}
                
                {elementoSelecionado && elementoSelecionado.lojaMergida ? (
                   <div>
                        <Box>
                            <LojaMergidaInfo lojaMergida={elementoSelecionado.lojaMergida}/>
                        </Box>

                        <Box pt={2}>
                            <BandeiraMergidaInfo bandeiraMergida={elementoSelecionado.bandeiraMergida}/>
                        </Box>
                   </div>
                   

                ): null}

            </Grid>

        </Grid>


            
        </Box>
        
    
        </div>
     </Template>
  );
}
