import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Box } from '@material-ui/core';
import { Configuracoes } from './components/configuracoes';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}
      <Link color="inherit" href="https://parseint.com.br/">
      Ulisses / parseint.com.br
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

export default function Template({children}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="lg">

      <Box pb={3}>
        <Typography variant="h4" component="h1" align="center" gutterBottom >
          Comparador de planilhas
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
         da Silvia 😉
        </Typography>
        <Configuracoes />
      </Box>
        
         {children}
      </Container>
     
      { /* 
      <footer className={classes.footer}>
        <Container maxWidth="sm"> 
          <Copyright />
        </Container>
      </footer>

      */}
    </div>
  );
}