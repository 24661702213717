import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button, Grid, TextField, DialogActions, DialogContent} from '@material-ui/core';
import { BANDEIRA_FLEX_TO_CONCIFLEX, loadConfigCardFromStorage } from '../helper/Classes';
import { useSnackbar } from 'notistack';


export function Configuracoes(){
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <div>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Configurações
        </Button>

        <SimpleDialog  open={open} onClose={handleClose} />
        </div>
    )

}



function SimpleDialog(props) {
    
    const { onClose, open } = props;
    const [configuracoesCartoes, setConfiguracoesCartoes] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() =>{
        if (open){
            
            try{
                setConfiguracoesCartoes(JSON.stringify( loadConfigCardFromStorage('BANDEIRA_FLEX_TO_CONCIFLEX', BANDEIRA_FLEX_TO_CONCIFLEX), null, 2))
            }catch(err){
                handleResetDefaults()
            }
        }
    }, [open])
  

    const handleChange = (event) => {
        setConfiguracoesCartoes(event.target.value);
    };

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        try{
            JSON.parse(configuracoesCartoes)
        }catch(err){
            enqueueSnackbar(`Formato do JSON configurado é inválido. Erro: ${err} `, { 
                variant: 'error',
            });

            return;
        }

        window.localStorage.setItem('BANDEIRA_FLEX_TO_CONCIFLEX', configuracoesCartoes);
        onClose();
    }

    const handleResetDefaults = () => {
        setConfiguracoesCartoes(JSON.stringify(BANDEIRA_FLEX_TO_CONCIFLEX, null, 2))
        window.localStorage.removeItem('BANDEIRA_FLEX_TO_CONCIFLEX');
    }
  
    return (
      <Dialog onClose={handleClose} 
         fullWidth
         open={open}>
        <DialogTitle >Configurações</DialogTitle>

        <DialogContent>
        <Grid container spacing={2}>
            <Grid item xs={12}>
            <TextField
                fullWidth
                label="Configurações dos cartões"
                multiline
                value={configuracoesCartoes}
                onChange={handleChange}
                rows={30}
                variant="outlined"
            />
            </Grid>
        </Grid>
        </DialogContent>

        <DialogActions>
            <Button onClick={handleClose} color="primary">
            Cancelar
            </Button>

            <Button onClick={handleResetDefaults} color="primary">
            Restaurar
            </Button>

            <Button onClick={handleSave} color="primary" variant='contained' >
                Confirmar
            </Button>
        </DialogActions>

        </Dialog>
    );
  }