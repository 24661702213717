import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { Box, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorIcon from '@material-ui/icons/Error';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import { isTotalEquals } from '../helper/ComparatorUtil';
import StorefrontIcon from '@material-ui/icons/Storefront';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';


const useStyles = makeStyles({
    root: {
      height: 110,
      flexGrow: 1,
      maxWidth: 400,
    },
  });
  
const redBGColor = red[50]
const greenBGColor = green[50] 


export default function TreeLoja({ dados, onClickBandeira }) {
  const classes = useStyles();


  const renderTree = (dados) => (
    <TreeItem key={dados.id} nodeId={dados.id} label={
        <TreeItemPrincipal/>
    }>
      { (dados.lojasMergidas && Array.isArray(dados.lojasMergidas)) ? dados.lojasMergidas.map((lojaMergida) => renderLojaMergida(lojaMergida)) : null}
    </TreeItem>
  );

  const renderLojaMergida = (lojaMergida) => (
    <TreeItem key={lojaMergida.id} nodeId={lojaMergida.id} label={<TreeItemLoja loja={lojaMergida} /> } >
        { (lojaMergida.bandeiras && Array.isArray(lojaMergida.bandeiras)) ? lojaMergida.bandeiras.map((bandeira) => renderBandeiraMergida(bandeira, lojaMergida)) : null}
    </TreeItem>
  );

  
  const renderBandeiraMergida = (bandeiraMergida, lojaMergida) => (
    <TreeItem key={bandeiraMergida.id} 
        nodeId={bandeiraMergida.id} 
        label={<TreeLabelBandeira bandeira={bandeiraMergida} /> }
        onClick={()=> onClickBandeira(bandeiraMergida, lojaMergida)}>
      
    </TreeItem>
  );


  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(dados)}
    </TreeView>
  );
}

function TreeItemPrincipal(){
    return (
        <div>
        <Box display="flex" p={1} >
            <Box flexGrow={1} >
                <Typography variant="body1" >
                    Lojas
                </Typography>
            </Box>
            <Box >
                <StorefrontIcon/> 
            </Box>
      </Box>
      </div>
    )
}


function TreeItemLoja({loja}){
    const isEquals = isTotalEquals(loja.valorTotalConciflex, loja.valorTotalFlex)
    return (
        <div>
        <Box display="flex" p={1} >
            <Box  flexGrow={1} >
                <Typography variant="body1" >
                    {loja.descricao}
                </Typography>
            </Box>
            <Box >
                <Typography variant="caption" color="inherit">
                    { isEquals ?
                    <DoneAllIcon style={{ color: green[400] }} /> : <ErrorIcon style={{ color: red[300] }}/> }
                </Typography>
            </Box>
      </Box>
      </div>
    )
}


function TreeLabelBandeira({bandeira}){
    return (
        <div>
        <Box display="flex" p={1} >
            <Box  flexGrow={1} >
                <Typography variant="body2" >
                    {bandeira.descricao}
                </Typography>
            </Box>
            <Box >
                <Typography variant="caption" color="inherit">
                    {isTotalEquals(bandeira.valorTotalConciflex, bandeira.valorTotalFlex) ?
                    <CheckIcon  style={{ color: green[400] }}/> : <SentimentDissatisfiedIcon style={{ color: red[300] }} /> }
                </Typography>
            </Box>
      </Box>
      </div>
    )
}