import * as XLSX from 'xlsx';
import normalize from 'normalize-strings'
import { Loja, 
        Bandeira, 
        LOJA_CONCIFLEX_TO_FLEX,
        BANDEIRA_FLEX_TO_CONCIFLEX,
        LojaMergida,
        BandeiraMergida,
        loadConfigCardFromStorage
} from './Classes';
var currencyFormatter = require('currency-formatter');

/**
 * Configurações globais
 */

export const CONFIG_OPTION_FILES = {
    CONCIFLEX: {
        name: 'CONCIFLEX',
        firstRow: 4,
        dateFormat: 'd/m/yy',
        storeCol: 1,
        dateCol: 3,
        cardCol: 10,
        valueCol: 15,
        authCol: 11
     },

     FLEX: {
        name: 'FLEX',
        firstRow: 11,
        dateFormat: 'd/m/yy',
        storeCol: 1,
        dateCol: 0,
        cardCol: 2,
        valueCol: 3,
        authCol: 2
     }
}
 

export const isTotalEquals = (value01, value02) =>{
    const diff = Math.abs( value01 - value02 )
    return (diff <= 0.02)
}


export const isSimilarString = (string01, string02) =>{
    let string01N = normalize(string01).toLowerCase()
    let string02N = normalize(string02).toLowerCase()
    return string01N === string02N
}


export const formatMoney = (value) =>{
    return currencyFormatter.format(parseFloat(value), { locale: 'pt-BR' });

    //return parseFloat(value).toFixed(2)
}

 export const convertToArray = (xlsFile, onComplete, opt = {sheetIndex: 0}) => {

    var reader = new FileReader();
    reader.onload = function (e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
            type: 'binary' 
        });
        var first_worksheet = workbook.Sheets[workbook.SheetNames[opt.sheetIndex]];

        var sheetArray = XLSX.utils.sheet_to_row_object_array(first_worksheet, {header:1,  raw:false});
        //console.log("objectArray", sheetArray)

        onComplete( sheetArray );
    };
    
    reader.readAsBinaryString(xlsFile);
 }


 
 export const convertSheetArrayToLojaArray = ( sheetArray, config ) => {
    console.log("config", config)
    return new Promise(function(resolve, reject) {

        try{
            const lojas = []
            console.log("PRIMEIRA LINHA " + config.name, sheetArray[config.firstRow])
            for(let i = config.firstRow; i < sheetArray.length; i++){
                
                const row = sheetArray[i];
                const store = row[config.storeCol]
                //const dateCol = row[config.dateCol]
                const cardCol = row[config.cardCol]
                const valueStringCol = row[config.valueCol]
                const authCol = row[config.authCol]

                const valueCol= valueStringCol ? parseFloat( String(valueStringCol).replace(",","") ) : 0.0

                if (i ===  config.firstRow){
                    console.log(config.name + " CONTEUDO LINHA " + i, store, cardCol, valueCol)
                }
                
                /* SE NOME DA LOJA VAZIO, PROVAVELMENTE TERMINOU A PLANILHA */
                if (!store){
                    continue;
                }
        
                const agrupador = store;
        
                let lojaEncontrada = lojas.find(lojaJaAdicionada => isSimilarString(lojaJaAdicionada.agrupador , agrupador))
                if (!lojaEncontrada){
                    lojaEncontrada = new Loja()
                    lojaEncontrada.agrupador = agrupador;
                    lojas.push(lojaEncontrada)
                }
                lojaEncontrada.totalGeral += valueCol ;


                const agrupadorBandeira = cardCol;
                let bandeiraEncontrada = lojaEncontrada.bandeiras.find(bandeiraJaExistente => isSimilarString( bandeiraJaExistente.agrupador, agrupadorBandeira) )
                if (!bandeiraEncontrada){
                    bandeiraEncontrada = new Bandeira();
                    bandeiraEncontrada.agrupador = agrupadorBandeira;
                    lojaEncontrada.bandeiras.push(bandeiraEncontrada)
                }
                bandeiraEncontrada.totalGeral += valueCol;
                bandeiraEncontrada.vendas.push(row)
                bandeiraEncontrada.cartoes.push({
                    descricao: authCol,
                    valor: valueCol
                })
 
        
            }
    
            resolve(lojas)
        }catch(err) {
            reject(err)
        }
       
    })
    
 }


 /**
  * 
  * @param {*} lojasConciflex - lojas conciflex
  * @param {*} lojasFlex - lojas do flex
  */
 export const mergeLojas = (lojasConciflex, lojasFlex) => {

    const lojasMergidas = []

    lojasConciflex.map(lojaConciflex => {

        const lojaMergida = new LojaMergida()
        lojasMergidas.push(lojaMergida)
        lojaMergida.descricao = lojaConciflex.agrupador;
        lojaMergida.valorTotalConciflex = lojaConciflex.totalGeral
        lojaMergida.descricaoLojasMergidas.push(lojaConciflex.agrupador)
  

        const lojasFlexEquivalente = lojasFlex.filter(lojaFlex => {

            const lojaConciflexDEPARA = LOJA_CONCIFLEX_TO_FLEX[lojaConciflex.agrupador];
            if (!lojaConciflexDEPARA){
                throw "Não foi encontrada a loja "+lojaConciflex.agrupador + " nas configurações da CONCIFLEX TO FLEX"
            }

            let indexLojaFlex = lojaConciflexDEPARA.findIndex( descLojaFlex => isSimilarString(descLojaFlex, lojaFlex.agrupador))
            return indexLojaFlex >= 0;
        } )

        if (lojasFlexEquivalente && lojasFlexEquivalente.length > 0){

            lojasFlexEquivalente.map(lojaFlexEquivalente => {
                /* INFORMA OS TOTAIS POR LOJA */
                lojaMergida.valorTotalFlex += lojaFlexEquivalente.totalGeral
                lojaMergida.descricaoLojasMergidas.push(lojaFlexEquivalente.agrupador)

                /* MERGIR AS BANDEIRAS AGORA */
                lojaFlexEquivalente.bandeiras.map(bandeiraLojaFlex => {

                    const bandeirasEncontradas = lojaMergida.bandeiras.filter(bandeiraJaAdicionada => {
                        return isSimilarString(bandeiraJaAdicionada.agrupador, bandeiraLojaFlex.agrupador)
                    })

                    let bandeiraMergida = null;
                    if (bandeirasEncontradas && bandeirasEncontradas.length > 0){
                        bandeiraMergida = bandeirasEncontradas[0];
                    }else{
                        bandeiraMergida = new BandeiraMergida()
                        lojaMergida.bandeiras.push(bandeiraMergida)
                        bandeiraMergida.descricao = bandeiraLojaFlex.agrupador;
                        bandeiraMergida.agrupador = bandeiraLojaFlex.agrupador;
                        bandeiraMergida.descricaoBandeirasMergidas.push(bandeiraLojaFlex.agrupador)
                    }
                    
                    bandeiraMergida.valorTotalFlex += bandeiraLojaFlex.totalGeral
                    bandeiraMergida.vendasFlex.push(... bandeiraLojaFlex.cartoes)

                    const BANDEIRA_FLEX_TO_CONCIFLEX_FROM_CONFIG = loadConfigCardFromStorage('BANDEIRA_FLEX_TO_CONCIFLEX', BANDEIRA_FLEX_TO_CONCIFLEX)

                    const bandeirasConciflexEquivalente = lojaConciflex.bandeiras.filter(bandeiraConciflex => {

                        const bandeiraFlexDEPARA = BANDEIRA_FLEX_TO_CONCIFLEX_FROM_CONFIG[bandeiraLojaFlex.agrupador]
                        if (!bandeiraFlexDEPARA){
                            throw "Não foi encontrada a bandeira '"+bandeiraLojaFlex.agrupador + "' nas configurações da FLEX TO CONCIFLEX"
                        }

                        let indexBandeiraConciflex = BANDEIRA_FLEX_TO_CONCIFLEX_FROM_CONFIG[bandeiraLojaFlex.agrupador].findIndex( descLojaConciflex => isSimilarString(descLojaConciflex, bandeiraConciflex.agrupador))
                        return indexBandeiraConciflex >= 0;
                    } )

                    if (bandeirasConciflexEquivalente && bandeirasConciflexEquivalente.length > 0 ){
                        //console.log("BANDEIRA ", bandeiraLojaFlex.agrupador, "Equivalente", bandeirasConciflexEquivalente)
                        
                        bandeirasConciflexEquivalente.map(bandeiraEquivalenteConciflec => {

                            if (bandeiraEquivalenteConciflec.somou){
                                console.log("Já somou a bandeira", bandeiraEquivalenteConciflec)
                            }else{
                                bandeiraEquivalenteConciflec.somou = true
                                bandeiraMergida.valorTotalConciflex += bandeiraEquivalenteConciflec.totalGeral
                                bandeiraMergida.descricaoBandeirasMergidas.push(bandeiraEquivalenteConciflec.agrupador)
                                bandeiraMergida.vendasConciflex.push(... bandeiraEquivalenteConciflec.cartoes)
                            }
                           
                        })
                    }

                })

            })
        }

    })
    

    return lojasMergidas;
 }