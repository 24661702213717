import React, { useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { CONFIG_OPTION_FILES, convertSheetArrayToLojaArray, convertToArray, formatMoney, isTotalEquals, mergeLojas } from '../helper/ComparatorUtil';
import StoreIcon from '@material-ui/icons/Store';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorIcon from '@material-ui/icons/Error';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';



import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

export function LojaMergidaInfo({lojaMergida}){
    
    const isEquals = isTotalEquals(lojaMergida.valorTotalConciflex, lojaMergida.valorTotalFlex)

    return (
        <Paper>
            <Box>
            
            <List >
                <ListItem>
                    <ListItemIcon>
                        <StoreIcon />
                    </ListItemIcon>

                    <ListItemText primary={lojaMergida.descricao} 
                        secondary={`Agrupou: ${lojaMergida.descricaoLojasMergidas}`} />
                
                    <ListItemSecondaryAction>
                    { isEquals ?
                        <DoneAllIcon style={{ color: green[400] }} /> : <ErrorIcon style={{ color: red[300] }}/> }
                    </ListItemSecondaryAction>
               </ListItem>

               <ListItem>
                <ListItemText primary={"Valor flex"} />
                   <ListItemSecondaryAction>
                    { formatMoney( lojaMergida.valorTotalFlex )}
                   </ListItemSecondaryAction>
              </ListItem>

              <ListItem>
                <ListItemText primary={"Valor conciflex"} />
                   <ListItemSecondaryAction>
                    { formatMoney( lojaMergida.valorTotalConciflex)}
                   </ListItemSecondaryAction>
              </ListItem>
            
              {isEquals ? null : (
                <ListItem>
                    <ListItemText primary={"Diferença"} secondary="(=) Flex - Conciflex"/>
                    <ListItemSecondaryAction>
                    { formatMoney( lojaMergida.valorTotalFlex - lojaMergida.valorTotalConciflex)}
                    </ListItemSecondaryAction>
                </ListItem>
              )}

            </List>
            
            </Box>
        </Paper>
    )
}


export function BandeiraMergidaInfo({bandeiraMergida}){

    const isEquals = isTotalEquals(bandeiraMergida.valorTotalConciflex, bandeiraMergida.valorTotalFlex)
    const [openCardsDialog, setOpenCardsDialogs] = useState(false)

    return (
        <Paper>
            <Box>
            
            <List >
              <ListItem>
                    <ListItemIcon>
                        <CreditCardIcon />
                    </ListItemIcon>

                    <ListItemText primary={bandeiraMergida.descricao} 
                    secondary={`Agrupou: ${bandeiraMergida.descricaoBandeirasMergidas}`} />
                
                    <ListItemSecondaryAction>
                    { isEquals ?
                        <DoneIcon style={{ color: green[400] }} /> : <SentimentDissatisfiedIcon style={{ color: red[300] }}/> }
                    </ListItemSecondaryAction>
               </ListItem>

               <ListItem>
                <ListItemText primary={"Valor flex"} />
                   <ListItemSecondaryAction>
                     { formatMoney( bandeiraMergida.valorTotalFlex )}
                   </ListItemSecondaryAction>
              </ListItem>

              <ListItem>
                <ListItemText primary={"Valor conciflex"} />
                   <ListItemSecondaryAction>
                     { formatMoney( bandeiraMergida.valorTotalConciflex)}
                   </ListItemSecondaryAction>
              </ListItem>

              {isEquals ? null : (
                <ListItem>
                    <ListItemText primary={"Diferença"} secondary="(=) Flex - Conciflex" />
                    <ListItemSecondaryAction>
                         { formatMoney( bandeiraMergida.valorTotalFlex - bandeiraMergida.valorTotalConciflex)}
                    </ListItemSecondaryAction>
                </ListItem>
              )}

            </List>
            
            <Button
                size="large"
                onClick={ () => setOpenCardsDialogs(true)} 
                fullWidth >Detalhar vendas</Button>
            
            </Box>

            <Dialog
                open={openCardsDialog}
                onClose={() => setOpenCardsDialogs(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">Detalhar vendas</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography>Vendas FLEX</Typography>
                        <MontarTableCartoes cartoes={bandeiraMergida.vendasFlex} />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography>Vendas CONCIFLEX</Typography>
                        <MontarTableCartoes cartoes={bandeiraMergida.vendasConciflex} />
                    </Grid>
                </Grid>

                    
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCardsDialogs(false)}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}


function MontarTableCartoes({cartoes}){

    const ordedCards = cartoes ? cartoes.sort( (cardA, cardB) => cardA.valor - cardB.valor ) : []

    return (
        <TableContainer  >

      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bolder" }}>Desc / Aut</TableCell>
            <TableCell  style={{ fontWeight: "bolder" }} align="right">Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ordedCards.map((row, i) => (
            <TableRow key={i}>
              <TableCell component="th" scope="row">
                {row.descricao}
              </TableCell>
              <TableCell align="right">{ formatMoney(row.valor)}</TableCell>
               
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    )
}