import './App.css';
import Comparador from './Comparador';
import { SnackbarProvider } from 'notistack';


function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <div className="App">
        <Comparador/>
      </div>
    </SnackbarProvider>

  );
}

export default App;
